<template>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
	<!-- <el-container class="homecontainer">
	  <el-header height="100px">
		 <div class="headerone"> 
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router>
			  <el-menu-item :index="'/'+item.path" v-for="(item,index) in headerlist" :key="index">{{item.name}}</el-menu-item>
			</el-menu> 
		 </div>
		  
	  </el-header>
	  <el-main>
		   路由占位符
		  <router-view></router-view>
	  </el-main>
	  <el-footer>Footer</el-footer>
	</el-container> -->
	
	
<div class="homecontainer">
	

		  <div style="width: 100%;height: 0.3rem;background-color: rgba(255,255,255,0.3);color: #FFFFFF;font-size: 0.14rem;display: flex;align-items: center;justify-content: space-between;" >
		  	<div class="yx">
				<div style="display: flex;align-items: flex-start;justify-content: center;margin-left: 0.3rem;height: 0.3rem;margin-top: 0.1rem;" >
					<p>邮箱：kf@biaozhuncha.com</p>
					<p style="margin-left: 0.2rem;">电话：400-6785-363</p>
				</div>
			</div>
		  	<div style="margin-right: 0.3rem;height: 0.3rem;margin-top: 0.1rem;" class="headertitle">标准查做您忠诚的标准专家、产品建议家</div>
		  </div>
		 
		  
	
	
		 <!-- <div style="min-height: 100%;margin-top: 0.1rem;"> -->
		  	<router-view></router-view>
		  <!-- </div> -->
	
	 
		  <!-- <div style="background-color: #2d2d2d;color: #ffffff;align-self: flex-end;width:100%;padding-left: 1.6rem;padding-right: 1.6rem;display: flex;flex-direction: column;box-sizing: border-box;">
		  	<div style="display: flex;justify-content: space-between;margin-top: 0.2rem;font-size: 0.14rem;box-sizing: border-box;">
			
					
					<div>
						<p style="font-size: 0.2rem;" class="befores">联系我们</p>
						<div style="display: flex;margin-top: 0.2rem;">
								<img src="../assets/images/address.png" style="width: 0.17rem;height: 0.2rem;" >
								<p style="margin-left: 0.16rem;">北京市顺义区空港街道空港融慧园6号楼</p>
						</div>
						<div style="display: flex;margin-top: 0.05rem;">
							<img src="../assets/images/email.png" style="width: 0.22rem;height: 0.18rem;" >
							<p style="margin-left: 0.16rem;">kf@biaozhuncha.com</p>
						</div>
						<div style="display: flex;margin-top: 0.05rm;">
							<img src="../assets/images/phone.png" style="width: 0.2rem;height: 0.19rem;" >
							<p style="margin-left: 0.16rem;">4006785363</p>
						</div>
					</div>
					
					<div style="display: flex;align-items: center;justify-content: center;">
						<div style="width: 0.5px;height: 0.5rem;background-color: #FFFFFF;"></div>
					</div>
		
					
					<div style="width: 5.77rem;">
						<p style="font-size: 0.2rem;" class="befores">关于我们</p>
						<p style="margin-top: 0.2rem;line-height: 0.25rem;">北京慧制科技有限公司（简称“慧制”）成立于2016年02月，落地于北京市顺义区海高大厦。在智慧城市建设的大背景下，慧制专注于智慧城市标准制定、规划咨询、国际合作、核心技术产业转化等领域，致力于为我们的客户提供卓有成效的、不可替代的优质服务。</p>
					</div>
					<div style="display: flex;align-items: center;justify-content: center;">
						<div style="width: 0.5px;height: 0.5rem;background-color: #FFFFFF;"></div>
					</div>
	
					<div style="display: flex;flex-direction: column;align-items: center;">
						<img src="../assets/images/erweima.png" style="width: 1.07rem;height: 1.07rem;">
						<p style="margin-top: 0.2rem;">扫一扫  下载标准查APP</p>
					</div>
			</div>
			
	
			<div style="display: flex;align-items: center;justify-content: center;">
				<p style="font-size: 0.2rem;">友情链接：</p>
				<div>
					<a href="https://www.zcsmart.com/" target="_blank"><img src="../assets/images/zckj.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
					<a href="http://www.antaiib.com/" target="_blank"><img src="../assets/images/antai.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
					<a href="https://www.isoftstone.com/" target="_blank"><img src="../assets/images/tong.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
					<a href="https://www.tencent.com/zh-cn" target="_blank"><img src="../assets/images/tencent.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
					<a href="https://www.aliyun.com/" target="_blank"><img src="../assets/images/ali.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
					<a href="http://www.sbcvc.com/" target="_blank"><img src="../assets/images/sbcvc.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
				</div>
			</div>
			<div  style="font-size: 0.14rem;display: flex;align-items: center;justify-content: center;margin-top: 0.15rem;">
				<a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="color: #ffffff;"><span style="margin-right: 20px;">© Copyright 2020 by北京慧制科技有限公司</span>
				<span>京ICP备2021012552号</span></a>
			</div>
			<div style="width: 100%;height: 0.2rem;"></div>
		  </div> -->
		  
		 <!-- <div>
			  {{jisuan()}}
		  </div> -->
		<Bottom></Bottom>

	
	
	
	
	<!-- 尾部 -->
	
	
<!-- <el-dialog
	  title="提示"
	  :visible.sync="dialogVisible"
	  width="30%"
	  :before-close="handleClose">
	  <span>这是一段信息</span>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="dialogVisible = false">取 消</el-button>
	    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
	  </span>
	</el-dialog> -->
	
</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Bottom from '../common/Bottom.vue'
export default {
  name: 'Home',
  components: {
    HelloWorld,
	Bottom
  },
  data(){
	  return{
		  activeIndex:'',
		   dialogVisible: false,
		   tokens:false,
		   headhost:'',
		  headerlist:[{
			  name:'首页',
			  path:'indext'
		  },
		 
		  {
		  			  name:'关于我们',
		  			  path:'about'
		  },
		  {
		  			  name:'录入',
		  			  path:'record'
		  }],
		  viptime:'',
		  vipflag:false
	  }
  },
 
  created() {
  	// this.loginout()
  },
  mounted() {
  	// this.loginout()
  },
    methods: {
		
		
		userqy(){
			console.log('头像点击');
		},
		jisuan(){
			let that = this
			var date = new Date();
			  var seperator1 = "-";
			  var year = date.getFullYear();
			  var month = date.getMonth() + 1;
			  var strDate = date.getDate();
			  if (month >= 1 && month <= 9) {
			    month = "0" + month;
			  }
			  if (strDate >= 0 && strDate <= 9) {
			    strDate = "0" + strDate;
			  }
			  var currentdate = year + seperator1 + month + seperator1 + strDate;
			   console.log(currentdate);
			   return currentdate
		},
		goindex(){
			this.$router.push({path:'/indext'})
		},
		coo(){
			// document.cookie = 'phone'
			console.log(document.cookie.name);
		},
		loginout(){
			let token = JSON.parse(window.localStorage.getItem('token'))
			console.log(token);
			if(token==null){
				this.tokens = false
			}else{
				this.tokens = true
				// console.log(token);
				if(token.info.telephone){
					this.$axios({
						url:'/push_info/',
						params:{
							input:token.info.telephone
						}
					}).then(res=>{
						console.log(res);
						window.localStorage.setItem('token',JSON.stringify(res.data))
						this.headhost = 'https://www.biaozhuncha.com/api/media/'+res.data.info.headshot
						if(res.data.info.over_time_day){
							this.viptime = res.data.info.over_time_day.slice(0,10)
						}
						this.vipflag = res.data.info.is_vip
					})
				}
				
			}
			
		},
		layout(){
			
			
			this.$axios({
				url:'/logout/'
			}).then(res=>{
				window.localStorage.removeItem('token')
				this.$message.success('退出成功')
				this.loginout()
			})
		},
        handleSelect(key, keyPath) {
          // console.log(key, keyPath);
        },
		  handleClose(done) {
		        this.$confirm('确认关闭？')
		          .then(_ => {
		            done();
		          })
		          .catch(_ => {});
		      },
		linkheader(item){
			// console.log(item)
			// if(item.path=='indext'){
			// 	this.$router.push({path:'/indext'})
			// }
			// if(item.path=='news'){
			// 	this.$router.push({path:'/news'})
			// }
			// if(item.path=='about'){
			// 	this.$router.push({path:'/about'})
			// }
				let token = JSON.parse(window.localStorage.getItem('token'))
				
				if(item.path=='record'){
					if(token==null){
						this.$message.error('您还没有登录，请先登录！')
					}else{
						this.$router.push({path:'/'+item.path})
					}
				}else{
					this.$router.push({path:'/'+item.path})
				}
		},
		pathLogin(){
			this.$router.push({path:'/login'})
		}
		    
      }
}
</script>
<style>
    body{
       margin:0;
       padding:0;
     }
 </style>
<style scoped="scoped">
	.homecontainer{
		width: 100%;
	/* 	border: 1px solid; */
		background: url(../assets/images/indexheadeerimg.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		margin: 0;
		padding: 0;
		
	}
	 @media screen and (max-width: 760px) {
			
		.homecontainer{
			/* border: 1px solid blue; */
			width: 1920px;
	
			
		}
	
		.headertitle{
			display: none;
		}
		.yx{
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.rightlogoq{
			margin-left: 0.7rem;
		}
	} 
.el-header{
	padding: 0;
}
	.logos{
		width: 0.6rem;
		height: 0.6rem;
		margin-left: 2.4rem;
	}
	  .home{
		  height: 100%;
	  }
	  .homecontainer{
	
		  height: 100vh;
	
	  }
	  .headerone{
		  width: 100%;
		  /* height: 100px; */
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  overflow: hidden;
	  }
	  .befores{
		  position: relative;
	  }
	 .befores:before{
		 content: "";/*单引号双引号都可以，但必须是英文*/
		     width: 0.4rem;
		     height: 1px; /*下划线高度*/
		     background: #FFFFFF; /*下划线颜色*/
		     position: absolute;
			 top: 110%;;
	 }
	 .rightlogo{
		 margin-right: 2.4rem;
		 width: 3.8rem;
		 display: flex;
		 align-items: center;
		 justify-content: space-between;
		 flex-wrap: nowrap;
		 color: #FFFFFF;
		 font-size: 0.16rem;
	 }
	.button1 {
	 background-color: #905321; /* 按钮颜色 */
	   border: none;
	   color: white; /* 文字颜色 */
	   padding: 10px 20px; /* 内部填充 */
	   text-align: center; /* 文字居中 */
	   text-decoration: none;
	   display: inline-block;
	   font-size: 14px; /* 字体大小 */
	   margin: 4px 2px;
	   cursor: pointer;
	   transform: skew(-20deg); /* 倾斜度 */
	   transition: 0.3s;
	   border-radius: 10px 4px 10px 4px;
	}
	 .button1:hover{
		 background-color: #92643e;
	 }
	 .nottitle{
		 background-color: #fceebf;
		 color: #a57c56;
		 border-radius: 10px;
		 padding: 15px 30px;
		 text-align: center;
		 font-weight: 700;
		 margin-top: 10px;
	 }
</style>

